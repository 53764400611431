export default {
  "AboutView": {
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the fascinating world of our unique necklaces! Our handcrafted jewelry made from olive wood, enhanced with epoxy resin and natural oils, are true masterpieces. Each necklace tells its own story, combining the beauty and warmth of olive wood with the elegance of the glossy epoxy resin coating. From minimalist pendants to intricate designs - explore the diversity and uniqueness of our exclusive collection. Each necklace is carefully handcrafted, showcasing exquisite craftsmanship and creative passion. Immerse yourself in our world of olive wood necklaces and let yourself be enchanted by the natural beauty and distinctive charm of our jewelry."])}
  },
  "buttons": {
    "discoverButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "commingSoonButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out"])}
  },
  "contactUSView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We look forward to your message"])},
    "genderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
    "genderWomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "genderMen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "genderDivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
    "messageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message"])}
  },
  "discoverProductView": {
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Price"])},
    "numberOfPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Pieces"])},
    "disponibleNumberOfPiecesPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only"])},
    "disponibleNumberOfPiecesSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces are available"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Pay"])},
    "addToCartButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])}
  },
  "navbar": {
    "shopName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necklace Magic"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])}
  },
  "LoveSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together Forever"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Necklaces for Eternal Love"])}
  },
  "supportSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay Updated - Enter Your Email Address"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be the first to know about our latest news and special offers. Subscribe to our newsletter by entering your email address."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "GreatfulMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for subscribing! Stay tuned for exciting updates and exclusive offers delivered straight to your inbox."])}
  },
  "shoppingView": {
    "backShoppingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Shopping"])},
    "removeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "modifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "productQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "ProductTotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Pay"])},
    "payingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Pay"])},
    "thanksHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you so much for your purchase with us! We appreciate your trust in our company and look forward to welcoming you back to our store soon."])},
    "deliveryCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name*"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address*"])},
      "contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street*"])},
      "houseNbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House Number*"])},
      "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code*"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City*"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])}
    },
    "paymentCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "sendPaymentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your purchase! To proceed with your order, please click the button below. Once confirmed, we will send you an email with detailed instructions on the payment process."])},
      "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email."])},
      "paymentProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your confirmation! We have sent you an email with information regarding the payment process."])}
    }
  },
  "impressumSection": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partner"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible for content"])}
  },
  "yasminSection": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our Olivewood Wonders Shop! Explore the Fusion of Nature and Craftsmanship in Our Exquisite Jewelry Collection. Discover the Beauty Within."])}
  },
  "footer": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])}
  }
}