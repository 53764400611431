import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import store from '../store/index'

import DefaultLayout from '@/layouts/DefaultLayout'
import i18n from '@/i18n'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },

  {
    path: '/',
    redirect: `/${i18n.global.locale}/home`,
  },

  {
    path: '/:lang/home',
    name: 'Home',
    component: () => import('@/views/pages/Home'),
  },

  {
    path: '/:lang/discoverProduct/:p_id',
    name: 'DiscoverProduct',
    component: () => import('@/views/pages/DiscoverProduct'),
  },
  {
    path: '/:lang/shoppingCart',
    name: 'ShoppingCart',
    component: () => import('@/views/pages/ShoppingCart'),
  },
  {
    path: '/:lang/about/',
    name: 'About',
    component: () => import('@/views/pages/About'),
  },
  {
    path: '/:lang/contact/',
    name: 'Contact',
    component: () => import('@/views/pages/ContactMe'),
  },
  {
    path: '/:lang/impressum/',
    name: 'IMpressum',
    component: () => import('@/views/pages/Impressum'),
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DefaultLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/services',
        name: 'Services',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/services/products',
        children: [
          {
            path: '/services/overView',
            name: 'Overview',
            component: () => import('@/views/services/OverView'),
          },
          {
            path: '/services/addProduct',
            name: 'AddProduct',
            component: () => import('@/views/services/addProduct'),
          },
          {
            path: '/services/products',
            name: 'Products',
            component: () => import('@/views/services/Products'),
          },
          {
            path: '/services/orders',
            name: 'Orders',
            component: () => import('@/views/services/Orders'),
          },
        ],
      },
      {
        path: '/communication',
        name: 'Communication',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/communication/messages',
        children: [
          {
            path: '/communication/messages',
            name: 'Messages',
            component: () => import('@/views//communication/Messages'),
          },
          {
            path: '/communication/supports',
            name: 'Supports',
            component: () => import('@/views/communication/Supports'),
          },
        ],
      },
      {
        path: '/settings',
        name: 'Settings',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/settings/profile',
        children: [
          {
            path: '/settings/profile',
            name: 'Profile',
            component: () => import('@/views/settings/Profile'),
          },
          {
            path: '/settings/addAdmin',
            name: 'AddAdmin',
            component: () => import('@/views/settings/AddAdmin'),
          },
          {
            path: '/settings/adminList',
            name: 'AdminList',
            component: () => import('@/views/settings/AdminList'),
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // admin dashboard
  if (to.path === '/login' && store.state.user) {
    next('/dashboard')
    return
  }
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.user
  ) {
    next('/login')
    return
  }

  // Languages
  let currentLanguage = to.params.lang
  if (!currentLanguage) {
    i18n.global.locale = 'de'
  }
  next()
})

export default router
