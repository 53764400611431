<template>
  <CNavbar expand="lg" color-scheme="light" class="bg-light">
    <CContainer>
      <CNavbarBrand class="shopName fw-bold"
        >{{ $t('navbar.shopName') }}
        <CLink
          class="instagramIcon mx-2"
          href="https://www.instagram.com/necklacemagicshop/"
        >
          <CIcon icon="cibInstagram" size="xl"
        /></CLink>
      </CNavbarBrand>
      <CNavbarToggler
        aria-label="Toggle navigation"
        aria-expanded="{visible}"
        @click="visible = !visible"
      />
      <CCollapse class="navbar-collapse" :visible="visible">
        <CNavbarNav component="nav" class="navItems ms-auto">
          <a class="px-3 py-2 active">
            {{ $t('navbar.home') }}
          </a>
          <a
            class="px-3 py-2"
            @click="
              () => {
                this.$router.push(`/${$i18n.locale}/about`)
              }
            "
          >
            {{ $t('navbar.about') }}
          </a>
          <a
            class="px-3 py-2"
            @click="
              () => {
                this.$router.push(`/${$i18n.locale}/contact`)
              }
            "
          >
            {{ $t('navbar.concat') }}
          </a>
          <a
            class="px-3 py-2"
            @click="
              () => {
                this.$router.push(`/${$i18n.locale}/shoppingCart`)
              }
            "
          >
            {{ $t('navbar.cart') }}
          </a>

          <CNavLink href="#"> <LanguageSwitcher /> </CNavLink>
        </CNavbarNav>
      </CCollapse>
    </CContainer>
  </CNavbar>
</template>

<script>
import LanguageSwitcher from '@/components/homePage/LanguageSwitcher'
export default {
  name: 'Navbar',
  components: { LanguageSwitcher },
  data() {
    return {
      visible: false,
    }
  },
}
</script>

<style lang="scss">
.navbar {
  z-index: 999;
}
.shopName {
  font-family: 'Alegreya', serif;
  font-size: 2rem !important;
  color: #7a6b53;
  &:hover {
    color: #7a6b53;
  }
}
.navItems a {
  cursor: pointer;
  font-family: 'Alegreya', serif;
  text-decoration: none;
  color: #7a6b53;
  font-size: 1.55rem !important;
  &.active {
    font-weight: bold;
    color: #cb5766 !important;
  }
}
.instagramIcon {
  font-weight: bold;
  color: #cb5766 !important;
}

/*
@media (max-width: 991.98px) {
  .navItems a:last-of-type {
    width: 30% !important;
  }
}
*/
</style>
