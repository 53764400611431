export default {
  "AboutView": {
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans le monde fascinant de nos colliers uniques ! Nos bijoux artisanaux en bois d'olivier, sublimés avec de la résine époxy et des huiles naturelles, sont de véritables chefs-d'œuvre. Chaque collier raconte sa propre histoire, combinant la beauté et la chaleur du bois d'olivier avec l'élégance du revêtement en résine époxy brillante. Des pendentifs minimalistes aux designs complexes - découvrez la diversité et l'unicité de notre collection exclusive. Chaque collier est soigneusement fabriqué à la main, mettant en valeur un savoir-faire exquis et une passion créative. Plongez dans notre univers de colliers en bois d'olivier et laissez-vous enchanter par la beauté naturelle et le charme distinctif de nos bijoux"])}
  },
  "buttons": {
    "discoverButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir"])},
    "commingSoonButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épuisé "])}
  },
  "contactUSView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous attendons avec impatience votre message"])},
    "genderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner"])},
    "genderWomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
    "genderMen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
    "genderDivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
    "messageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message"])},
    "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre message"])}
  },
  "discoverProductView": {
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire"])},
    "numberOfPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pièces"])},
    "disponibleNumberOfPiecesPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seulement"])},
    "disponibleNumberOfPiecesSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pièces disponibles"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous payez"])},
    "addToCartButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])}
  },
  "navbar": {
    "shopName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necklace Magic"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
    "concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])}
  },
  "LoveSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together Forever"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colliers de Partenaires pour un Amour Éternel"])}
  },
  "supportSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restez Informé(e) - Entrez Votre Adresse E-mail"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez le/la premier(ère) à être informé(e) de nos dernières actualités et offres spéciales. Abonnez-vous à notre newsletter en entrant votre adresse e-mail."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "GreatfulMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de vous abonner ! Restez à l'affût des dernières mises à jour et offres exclusives directement dans votre boîte de réception."])}
  },
  "shoppingView": {
    "backShoppingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux achats"])},
    "removeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "modifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "productQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "ProductTotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous payez"])},
    "payingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passons au paiement"])},
    "thanksHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci beaucoup pour votre achat chez nous ! Nous apprécions votre confiance envers notre entreprise et avons hâte de vous accueillir à nouveau chez nous."])},
    "deliveryCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de Livraison"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom*"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail*"])},
      "contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue*"])},
      "houseNbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de maison*"])},
      "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal*"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville*"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])}
    },
    "paymentCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
      "sendPaymentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre achat ! Veuillez cliquer sur le bouton de confirmation ci-dessous afin que nous puissions vous envoyer un e-mail expliquant le processus de paiement."])},
      "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre courrier électronique."])},
      "paymentProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre confirmation ! Nous vous avons envoyé un e-mail avec des informations concernant le processus de paiement."])}
    }
  },
  "impressumSection": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partenaire"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable du contenu"])}
  },
  "yasminSection": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans notre boutique Olivewood Wonders ! Découvrez la fusion de la nature et de l'artisanat dans notre collection de bijoux exquis. Découvrez la beauté intérieure."])}
  },
  "footer": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propulsé par"])}
  }
}