<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <p class="mx-2 text-danger fs-5">
            {{ this.$store.state.adminStatus ? 'super admin' : 'admin' }}
          </p>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav class="d-none d-md-flex ms-auto">
        <CNavItem>
          <CButton @click.prevent="logout" class="text-white" color="danger"
            >Logout</CButton
          >
        </CNavItem>
      </CHeaderNav>
    </CContainer>

    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
  },
  data() {
    return {
      name: null,
      superAdmin: null,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>
