import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire'
import { firebaseApp } from './firebase'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import Navbar from '@/components/homePage/Navbar'
import i18n from './i18n'

const app = createApp(App).use(i18n)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueFire, { firebaseApp, modules: [VueFireFirestoreOptionsAPI()] })
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Navbar', Navbar)

app.mount('#app')
