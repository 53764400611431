<template>
  <div class="languageSwitcherBox fs-5 px-3">
    <CFormSelect v-model="selectedLanguage">
      <option value="en">EN</option>
      <option value="de">DE</option>
      <option value="fr">FR</option>
      <option value="tn" disabled>TUN</option>
    </CFormSelect>
  </div>
</template>
<script>
import i18n from '@/i18n'
export default {
  data() {
    return {
      selectedLanguage: i18n.locale,
    }
  },
  watch: {
    selectedLanguage(l) {
      i18n.global.locale = l
    },
  },
  created() {
    this.selectedLanguage = i18n.global.locale
  },
}
</script>
<style lang="scss">
.languageSwitcherBox .form-select {
  color: #7a6b53;
  background-color: transparent;
  border: 2px solid #cb5766;
  padding: 0 24px;
  option {
    display: inline-block;
    padding: 10px !important;
    &:hover {
      background: black !important;
    }
  }
}

.languageSwitcherBox .form-select:focus {
  outline: none;
  box-shadow: none;
}

/* .languageSwitcherBox option[value='en'] {
  width: 100%;
  height: 100;
  background-image: url('@/assets/icons/flags/flag_en.svg');
} */
/* .languageSwitcherBox option[value='female'] {
  background-image: url(female.png);
}
.languageSwitcherBox option[value='others'] {
  background-image: url(others.png);
} */
</style>
