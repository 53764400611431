import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import router from '../router'
import Swal from 'sweetalert2'
import { Toast } from '@/utlis/alert'

import { doc, setDoc, getDoc } from 'firebase/firestore'

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'

import { auth, db } from '../firebase'

export default createStore({
  state: {
    //CUI
    sidebarVisible: '',
    sidebarUnfoldable: false,

    //Authentication
    user: null,
    adminStatus: false,

    //ShoppingCart
    cart: [],
  },
  mutations: {
    //GUI
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },

    //Authentication
    SET_USER(state, currentUser) {
      state.user = currentUser
    },
    SET_ADMIN_STATUS(state, adminStatus) {
      state.adminStatus = adminStatus
    },
    CLEAR_USER(state) {
      state.user = null
    },

    //ShoppingCart
    addToCart(state, newProduct) {
      let found = state.cart.find((p) => p.productID == newProduct.productID)
      if (found) {
        found.numberOfPieces++
      } else {
        state.cart.push(newProduct)
      }
    },
    removeFromCart(state, product) {
      let index = state.cart.indexOf(product)
      state.cart.splice(index, 1)
    },
    clearCart(state) {
      state.cart = []
    },
  },

  actions: {
    // Begin Authentication
    async login({ commit }, details) {
      const { email, password } = details
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const docRef = doc(db, 'admins', userCredential.user.uid)
          const adminRef = await getDoc(docRef)
          const adminInfos = adminRef.data()
          commit('SET_ADMIN_STATUS', adminInfos.superAdmin)
          commit('SET_USER', userCredential.user.uid)

          router.push('/settings/profile')
        })
        .then(() => {
          Toast.fire({
            icon: 'success',
            title: 'Welcome',
          })
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          Swal.fire({
            icon: 'error',
            title: errorCode,
            text: errorMessage,
          })
          return
        })
    },
    async addAdmin({ commit }, details) {
      const { email, password } = details
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setDoc(doc(db, 'admins', userCredential.user.uid), {
            email: email,
            password: password,
            superAdmin: false,
          })
          router.push('/dashboard')
        })
        .then(() => {
          Toast.fire({
            icon: 'success',
            title: 'new Admin is successfully added',
          })
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          Swal.fire({
            icon: 'error',
            title: errorCode,
            text: errorMessage,
          })
          return
        })
    },

    async logout({ commit }) {
      signOut(auth)
        .then(() => {
          commit('CLEAR_USER')
          router.push('/login')
        })
        .catch((error) => {})
    },
  },
  getters: {
    getTotalPrice(state) {
      let totalPrice = 0
      for (const p of state.cart) {
        totalPrice += p.unitPrice * p.numberOfPieces
      }
      return totalPrice
    },
  },

  plugins: [createPersistedState()],
})
