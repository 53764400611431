export default {
  "AboutView": {
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen in der faszinierenden Welt unserer einzigartigen Halsketten! Unsere handgefertigten Schmuckstücke aus Olivenholz, veredelt mit Epoxidharz und natürlichen Ölen, sind wahre Meisterwerke. Jede Halskette erzählt ihre eigene Geschichte und vereint die Schönheit und Wärme des Olivenholzes mit der Eleganz der glänzenden Epoxidharzbeschichtung. Von minimalistischen Anhängern bis hin zu kunstvollen Designs - entdecken Sie die Vielfalt und Einzigartigkeit unserer exklusiven Kollektion. Jede Halskette ist sorgfältig handgefertigt und zeugt von meisterhaftem Handwerk und kreativer Leidenschaft. Tauchen Sie ein in unsere Welt der Olivenholz-Halsketten und lassen Sie sich von der natürlichen Schönheit und dem unverwechselbaren Charme unserer Schmuckstücke verzaubern."])}
  },
  "buttons": {
    "discoverButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecken"])},
    "commingSoonButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausverkauft"])}
  },
  "contactUSView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns auf deine Nachricht"])},
    "genderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "genderWomen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
    "genderMen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mann"])},
    "genderDivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
    "messageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachricht"])},
    "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Nachricht"])}
  },
  "discoverProductView": {
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückpreis"])},
    "numberOfPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Stücke"])},
    "disponibleNumberOfPiecesPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur"])},
    "disponibleNumberOfPiecesSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stücke verfügbar"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie zahlen"])},
    "addToCartButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Warenkorb legen"])}
  },
  "navbar": {
    "shopName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necklace Magic"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenkorb"])}
  },
  "LoveSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together Forever"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerketten für ewige Liebe"])}
  },
  "supportSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleiben Sie auf dem Laufenden - Tragen Sie Ihre E-Mail-Adresse ein"])},
    "describtion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpassen Sie keine Neuigkeiten und exklusiven Angebote! Bleiben Sie informiert und tragen Sie Ihre E-Mail-Adresse ein, um als Erster von unseren aktuellen Updates und Aktionen zu erfahren."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "GreatfulMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Anmeldung! Freuen Sie sich auf spannende Updates und exklusive Angebote, die direkt in Ihr Postfach geliefert werden."])}
  },
  "shoppingView": {
    "backShoppingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Einkaufen"])},
    "removeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "modifyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "productQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "ProductTotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtpreis"])},
    "youPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie zahlen"])},
    "payingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung abschließen"])},
    "thanksHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihren Einkauf bei uns! Wir schätzen Ihr Vertrauen in unser Unternehmen und freuen uns darauf, Sie bald wieder bei uns begrüßen zu dürfen."])},
    "deliveryCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname*"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse*"])},
      "contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße*"])},
      "houseNbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer*"])},
      "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl*"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort*"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])}
    },
    "paymentCart": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung"])},
      "sendPaymentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihren Einkauf! Bitte klicken Sie auf den Bestätigungsbutton, damit wir Ihnen eine E-Mail mit ausführlichen Anweisungen zum Zahlungsprozess zusenden können."])},
      "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
      "checkEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail."])},
      "paymentProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Bestätigung. Wir haben Ihnen eine E-Mail gesendet, in der wir Sie über den Zahlungsprozess informieren werden."])}
    }
  },
  "impressumSection": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaltlich verantwortlich"])}
  },
  "yasminSection": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen in unserem Olivewood Wonders Shop! Entdecken Sie die Fusion von Natur und Handwerkskunst in unserer exquisiten Schmuckkollektion. Entdecken Sie die Schönheit im Inneren."])}
  },
  "footer": {
    "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])}
  }
}