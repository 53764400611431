import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

// Initialize Firebase
export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyAA7hd9jFyfVSdhFFg8f5rVWFxWT6r1cDo',
  authDomain: 'necklaces-shop.firebaseapp.com',
  projectId: 'necklaces-shop',
  storageBucket: 'necklaces-shop.appspot.com',
  messagingSenderId: '2883344890',
  appId: '1:2883344890:web:0cbf3df78702ff36eaec43',
  measurementId: 'G-D8WK5TSN5D',
})

export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)
//export const productsDB = collection(db, 'products')
//export const storage = getStorage(app)
