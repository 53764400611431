export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  // services
  {
    component: 'CNavTitle',
    name: 'Service',
  },
  {
    component: 'CNavItem',
    name: 'Overview',
    to: '/services/overview',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'AddProduct',
    to: '/services/addProduct',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'Products',
    to: '/services/products',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'Orders',
    to: '/services/orders',
    icon: 'cil-drop',
  },

  //communication
  {
    component: 'CNavTitle',
    name: 'Communication',
  },
  {
    component: 'CNavItem',
    name: 'Messages',
    to: '/communication/messages',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'Supports',
    to: '/communication/supports',
    icon: 'cil-drop',
  },

  // settings
  {
    component: 'CNavTitle',
    name: 'Settings',
  },
  {
    component: 'CNavItem',
    name: 'Profile',
    to: '/settings/profile',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'AddAdmin',
    to: '/settings/addAdmin',
    icon: 'cil-drop',
  },
  {
    component: 'CNavItem',
    name: 'AdminList',
    to: '/settings/adminList',
    icon: 'cil-drop',
  },
]
